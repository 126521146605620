@tailwind base;

@tailwind components;

@tailwind utilities;


html {
    scroll-behavior: smooth;
}
